<template>
  <header>
    <v-container fluid class="grey lighten-5">
        <v-row>
          <v-col style="display: flex; align-items: center; justify-content: center" v-if="$vuetify.breakpoint.mdAndUp">
            <div class="d-flex">
              <v-btn depressed rounded retain-focus-on-click
                     color="grey lighten-3" width="160" height="46"
                     :to="{ name: 'Home' }"
                     exact
              >
                Управителям
              </v-btn>
              <div class="ml-3 font-weight-medium">
                <v-btn depressed rounded retain-focus-on-click
                       color="grey lighten-3" width="160" height="46"
                       href="https://wetoo.vinksoft.com.ua/login?demo=true"
                >
                  Демонстрація
                </v-btn>
              </div>
            </div>
            <div class="mx-12 my-4">
              <img :src="`${$publicPath}img/logos/logo_all_1.svg`" alt="logo" style="height: 56px; cursor: pointer"
                   @click.stop="$router.push({ name: 'Home' })"
              >
            </div>
            <div class="d-flex">
              <v-btn depressed rounded retain-focus-on-click
                     color="grey lighten-3" width="160" height="46"
                     :to="{ name: 'Contacts' }"
              >
                Контакти
              </v-btn>
              <div class="ml-3">
                <v-btn depressed rounded retain-focus-on-click
                       color="success" width="160" height="46"
                       href="https://wetoo.vinksoft.com.ua/login"
                >
                  Кабінет
                </v-btn>
              </div>
            </div>
            <v-fab-transition>
              <v-btn
                v-scroll="onScroll"
                v-show="showGoTop"
                fab
                dark
                fixed
                bottom
                right
                color="success"
                @click="toTop"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-col>
          <v-col v-else>
            <div class="mb-5">
              <v-btn depressed text fab @click="drawer = !drawer">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </div>
            <div>
              <img :src="`${$publicPath}img/logos/logo_all_1.svg`" alt="logo" style="height: 40px; cursor: pointer"
                   @click.stop="$router.push({ name: 'Home' })"
              >
            </div>
          </v-col>
          <v-navigation-drawer v-model="drawer" app temporary>
            <div style="display: flex; align-items: center; justify-content: center; margin-top: 24px; margin-bottom: 20px;">
              <img :src="`${$publicPath}img/logos/logo_all_1.svg`" alt="logo" style="height: 40px; cursor: pointer"
                   @click.stop="$router.push({ name: 'Home' })"
              >
            </div>
            <v-divider></v-divider>
            <v-list nav dense>
              <v-list-item color="success"
                           :to="{ name: 'Home' }"
                           exact
              >
                <v-list-item-content>
                  Управителям
                </v-list-item-content>
              </v-list-item>
              <v-list-item color="success"
                           href="https://wetoo.vinksoft.com.ua/login?demo=true"
              >
                <v-list-item-content>
                  Демонстрація
                </v-list-item-content>
              </v-list-item>
              <v-list-item color="success"
                           :to="{ name: 'Contacts' }"
              >
                <v-list-item-content>
                  Контакти
                </v-list-item-content>
              </v-list-item>
              <v-list-item color="success"
                           href="https://wetoo.vinksoft.com.ua/login"
              >
                <v-list-item-content>
                  Кабінет
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-row>
    </v-container>
  </header>
</template>

<script>
export default {
  name: 'NavDrawer',
  data () {
    return {
      showGoTop: false,
      drawer: false
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.showGoTop = top > 420
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>

</style>
